
/* ------------------------------ */
/* Type */

:root {
    --font-headings:            'Playfair Display', sans-serif;
    --font-base:                'Merriweather Sans', sans-serif;
    --font-color-base:          var(--black);
    --font-weight-base:         500;
    --font-line-height:         1.78;
    --font-headling-line-height:    1.36;

    --font-size-xs:     0.77rem;
    --font-size-sm:     0.88rem;
    --font-size-base:   1rem;
    --font-size-lg:     1.2rem;
    --font-size-xl:     1.5rem;
    --font-size-xxl:    2.2rem;


    h1,.h1  {font-size: var(--font-size-xxl);}
    h2,.h2  {font-size: var(--font-size-xl);}
    h3,.h3  {font-size: var(--font-size-lg);}
    h4,.h4  {font-size: var(--font-size-base);}
    h5,.h5  {font-size: var(--font-size-base);}
}

html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: #000000; // 6
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}


body {
    font-family: var(--font-base);
}

h1,h2 {
    font-family: var(--font-headings);
}