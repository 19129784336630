.infopagelist{
	
	

	.btn {
		justify-content: flex-start;
		background-color: var(--color-primary);

	}

	// hide home for now
	.Home {
		display: none;
	}
}