footer {
    background-color: var(--color-primary);
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 1fr;
    color: white;
    margin-top: var(--space-xl);
    padding: var(--box-padding-sm);
    .footer-logo {
    	img {
    		width: 100px;
    		height: auto;
    		margin-left: -3px;
    		margin-bottom: 3px;
    	}
    }
    address {
    	font-style: normal;
    	font-size: var(--font-size-sm);
    	a {
    		color: white;
    	}
    }
}
