
.selected-header {
    background-color: var(--blue);
    padding: var(--box-padding-sm);
    
    .nameHolder {
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;

        img {
            border-radius: 50%;
        }

        span.name {
            
            font-size: 24px;
            margin-right: 0.5rem;
            font-family: var(--font-headings);
        }


    }
    svg {
        stroke-width: 1;
    }
    .center-vertical {
        margin-top: auto;
        margin-bottom: auto;
    }

    &__data {
    	
    	display: flex;
    	font-size: var(--font-size-sm);
    	align-items: center;
    	justify-content: flex-start;
        
        span {
            margin-right: 0.5rem;    
        }
    }
}
/* 
.selected-header {
    background-color: #e7e2df;
    padding: 1rem;

    .nameHolder {
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;

        img {
            border-radius: 50%;
        }

        span.name {
            font-weight: bold;
            font-size: 24px;
        }
    }

    .center-vertical {
        margin-top: auto;
        margin-bottom: auto;
    }
}
 */