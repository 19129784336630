.ImageTextElement {
	//background-color: red;
	display: flex;
	flex-direction: column;
	

	h2 {
		order:2;
	}
	figure {
		order:1;
		margin: 0;
		img {
			width: 100%;
			height: auto;
		}

	}
	div {
		order: 3;

		p {
			margin-top: 0;
		}
	}
	.btn {
		order: 4;
	}



	&.border-1{
		border: 1px solid var(--grey-dark);
		padding: var(--box-padding);

		margin-top: var(--box-padding-sm);
		margin-bottom: var(--box-padding-sm);
	}
}
