.ElementContent {
	

	&.has-border {
		border: 1px solid var(--grey-dark);
		padding: var(--box-padding);

		margin-top: var(--box-padding-sm);
		margin-bottom: var(--box-padding-sm);
		h2 {
			margin-top: 0;
			font-size: var(--font-size-lg);
		}
		p {
			margin-top: 0;
		}
		p:last-of-type {
			margin-bottom: 0;
		}
	}
}