#root .react-datepicker {
	border: none;
	width: 100%;
	
	&__month-container {
		float: none;
	}
	&__header {
		background-color: white;
		border: none;
		width: 100%;
	
	}
	font-size: 1rem;
	&__navigation {
		top:-0.6rem;
	}
	&__day-name, &__day {
		width:2.4rem;
		line-height: 2.4rem;
	}
	&__day {
		border-radius: 50%;
		background-color: var(--color-primary);
		color: white;
		

	}
	&__day--disabled {
		border: none;
		background-color: white;
		color: var(--grey-dark);
	}
	&__day--selected {
		border: 2px solid black;
		
	}
}

.timeCard {
	border: 1px solid black;
}

.types-legend {	
	h5 {
		font-weight: normal;
		font-size: var(--font-size-sm);
	}
	ul {
		
		list-style-type: none;
		display: flex;
		justify-content:center;

		li {
			font-size: var(--font-size-sm);
			color:var(--grey-dark);
			display: flex;
			align-items: center;
			margin-right: 0.5rem;

			svg {
				margin-right: 0.5rem;				
				stroke-width: 1;
			}
		}
	}
}