/* ------------------------------ */
/* Colors */

:root {
    --orange:       #F68C01;
    --orange-light: #fbd199;

    --red:          #A5002D;
    --blue:         #D1EBF2;
    --blue-dark:    #17A2B8;
    --grey:         #E7E2DF;
    --grey-dark:    #9f9f9f;
    --black:        #000000;
    --white:        #ffffff;

    --color-primary:    var(--orange);
    --color-secondary:  var(--red);
}

/* ------------------------------ */
/* Spacing */

:root {
    --space-unit:  1em;
    --space-xs:    calc(0.30 * var(--space-unit));
    --space-sm:    calc(0.75 * var(--space-unit));
    --space-md:    calc(1.25 * var(--space-unit));
    --space-lg:    calc(2 * var(--space-unit));
    --space-xl:    calc(3 * var(--space-unit));

    --grid-gutter-xs:       5px;
    --grid-gutter-sm:       10px;
    --grid-gutter-md:       20px;
    --grid-gutter-lg:       60px;
    --grid-gutter-xl:       90px;

    --box-padding-sm:       1rem;
    --box-padding:          2rem;
    --box-padding-lg:       4rem;



    //border radius
    --border-radius-xs:     5px;
    --border-radius-sm:     25px;
    --border-radius-md:     25px;
    --border-radius-lg:     50px;

    // header
    --header-height:    80px;
    --header-height-sm: 60px;


}