#root{
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	position: relative;
	main{
		flex-grow: 1;
	}
}
body {
	background-color: white;
}
.section-basic {
	&--grey {
		background-color: var(--grey);
		padding:var(--box-padding);
	}
	&--orange {
		background-color: var(--orange);
		padding:var(--box-padding);
	}
	&--m {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	&__w-md {
		padding-left: var(--box-padding);
		padding-right: var(--box-padding);

	}
}

// elements
section.element{

}

@media only screen and (min-width: 600px) {

	//helper for view on desktop screen
	div#root {
		
		background-color: white;
		//max-width: 680px;

		//min-height: 90vh;

		
		overflow: hidden;

		main section {
			margin: 0 auto;
			max-width: 680px;			
		}
	}

	body {
		//background-color: var(--grey);
	}

}