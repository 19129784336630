


nav {
    background-color: var(--color-primary);
    padding: 0.5rem;
    display: grid;
    grid-template-columns: auto auto auto;
    color: white;
    font-weight: bold;
	.logo {
		width: 110px;
		height: auto;

	}

	.menu-toggle {

		justify-self: end;
		align-self: center;

	}
    .nav-siteinfo {
        //background-color: yellow;
        display: flex;
        align-items: center;
        font-size: var(--font-size-xs);
        font-weight: normal;
        line-height: 1.2;
        padding: 0 0.4rem;
    }
    .nav-container {
        grid-column: 1 / 4;
        transition: max-height .5s;
        max-height:0;
        overflow: hidden;

        &--open{
            max-height:1000px;
        }
    }
    ul.nav-content {
        list-style: none;
        //margin: 2rem 0 0;
        padding: 0;
        text-align: center;
        flex: 1 1 100%;
        grid-column: 1 / -1;


        //display: none;


        li {
            padding: 1rem;
            border-bottom: 1px solid white;
            cursor: pointer;

            &.first {
                border-top: 1px solid white;
            }
        }


    }
}



// nav menu toggle button

.menu-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    width:2rem;
    height:2rem;
    margin-left: auto;
    margin-top: 0;
    margin-bottom: 0;
    //z-index: calc(var(--z-nav-overlay) + 1);
    cursor: pointer;
    padding:0;
    background-color: transparent;
    color: var(--white);
    border: 0;
    &:hover {
        background-color: transparent;
        //color: var(--black);
    }

    &:focus {

    }
    &:active {
        transform: none;

    }
    i {
        position: relative;
        display: block;
        font-size: 28px;
        width: 1em;
        height: 2px;
        color: inherit;
        background-image: linear-gradient(currentColor,currentColor);
        background-repeat: no-repeat;
        transform: scale(1);

        background-size: 100% 100%;
        will-change: transform,background-size;
        transition-property: transform,background-size;
        transition-duration: 0.2s;

        &:before, &:after {
            will-change: inherit;
            transition: inherit;
        }
        &:before {
            transform: translateY(-0.25em) rotate(0);
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-image: inherit;
            border-radius: inherit;
        }

        &:after {
            transform: translateY(0.25em) rotate(0);
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-image: inherit;
            border-radius: inherit;
        }

    }

    &--open{
        i {
            background-size: 0% 100%;
            &:before {
                    transform: translateY(0) rotate(45deg)
            }
            &:after {
                    transform: translateY(0) rotate(-45deg)
            }
        }
    }

}