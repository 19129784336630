
.consultant-list {
    display: flex;
    flex-direction: column;
    //grid-template-columns: repeat(2, minmax(150px, 1fr)); 
    //grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
    
    

    .consultantCard {
        text-align: center;
        border-radius: 20px;
        background-color: var(--orange);
        width: 100%;
        display: flex;
        align-items:center;
        .consultantCard-content {
            margin: 2rem;
            flex: 1;
            text-align: left;
            color:white;
            h2, h3 {
                margin: 0 0 0.2rem 0;
                font-family: var(--font-headings);
            }

            h3 {
                font-size: var(--font-size-xl);
            }

        }

        .consultantImgHolder {
            flex: 1;
            display: flex;
            justify-content: center;
            margin: 2rem auto 2rem;
            //gap: 5px;

            img {
                border-radius: 50px;
                object-fit: cover;
            }
        }

        &.active {
            cursor: pointer;
      
        }
        .consultant--lang {
            display: flex;
            align-items:center;
        	img {
        		margin: 0 0.2rem;
        	}	
        }

        .available-dates {
        	
        	font-size: var(--font-size-sm);
        	margin: 0 0 0.4rem 0;
        }
    }
}