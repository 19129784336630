.HeaderElement {
	&__title{
		display: flex;
		figure {
			flex-basis: 33%;
			margin:1rem 1rem 0 0;
			img{
				width: 100%;
				height: auto;
			}
		}
		h2 {
			margin-top: 0;
			flex-basis: 66%;
    		font-family: var(--font-headings);
		}

/* 		h2::before {
		    content:"";
		    background-repeat: no-repeat;
		    width: 45px;
		    height: 45px;
		    display: block;
		    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 71 70' style='enable-background:new 0 0 71 70;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .quotest0%7Bfill:%23F68C01;%7D%0A%3C/style%3E%3Cpath class='quotest0' d='M29.44,17.64c-2.59,1.48-4.68,2.76-6.28,3.83c-1.6,1.07-3.22,2.48-4.87,4.24c-1.54,1.65-2.72,3.38-3.55,5.19 c-0.83,1.81-1.4,4.14-1.73,7h3.96c3.41,0,6.1,0.84,8.05,2.52c1.95,1.68,2.93,4.14,2.93,7.39c0,2.31-0.87,4.47-2.6,6.48 c-1.73,2.01-4.12,3.01-7.14,3.01c-4.74,0-8.12-1.55-10.16-4.66C6.02,49.54,5,45.45,5,40.4c0-3.57,0.77-6.83,2.31-9.77 c1.54-2.94,3.41-5.56,5.62-7.87c2.26-2.36,4.65-4.37,7.18-6.02c2.53-1.65,4.65-2.99,6.36-4.04L29.44,17.64z M60,17.64 c-2.59,1.48-4.68,2.76-6.28,3.83c-1.6,1.07-3.22,2.48-4.87,4.24c-1.6,1.7-2.79,3.44-3.59,5.23c-0.8,1.78-1.36,4.1-1.69,6.96h3.96 c3.41,0,6.1,0.84,8.05,2.52c1.95,1.68,2.93,4.14,2.93,7.39c0,2.31-0.87,4.47-2.6,6.48c-1.73,2.01-4.12,3.01-7.14,3.01 c-4.74,0-8.12-1.55-10.16-4.66c-2.04-3.1-3.06-7.19-3.06-12.24c0-3.57,0.77-6.83,2.31-9.77c1.54-2.94,3.41-5.56,5.61-7.87 c2.26-2.36,4.65-4.37,7.19-6.02c2.53-1.65,4.65-2.99,6.36-4.04L60,17.64z'/%3E%3C/svg%3E%0A");
		} */
	}
}
