

.form {

    background-color: var(--blue);
    border-radius: var(--border-radius-sm);
    padding: var(--box-padding);
    > p {
        margin-top: 0;
    }
    label {
        font-weight: bold;
        margin-bottom: 0.2rem;
        display: inline-block;
    }

    input, select, textarea {
        background-color: white;
        line-height: 1.5;
        box-sizing: border-box;
        border: none;
        height: 2.5rem;
        border-radius: var(--border-radius-xs);
        padding-bottom: calc(.675em - 1px);
        padding-left: calc(0.825em - 1px);
        padding-right: calc(0.825em - 1px);
        padding-top: calc(.675em - 1px);   
    }

    textarea {
        min-height: 5rem;
        width: 100%;
    }

    input[type="text"],
    input[type="email"],
    input[type="phone"]
    {
        width: 100%;
    }

    span.helper-text {
        color: var(--blue-dark);
        font-size: small;
    } 
}

input[type="checkbox"]{
    padding:0;
    margin:0;
    height: auto;
}

.form-checkbox {
    
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--box-padding-sm);
    input[type="checkbox"] {
        margin-right: 0.4rem;
        margin-top: 0.2rem;
    }

}

span.optional-info {
    color: var(--orange);
    font-size: x-small;
    margin-left: 5px;
}