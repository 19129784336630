header.titlerow-header {
	display: flex;
	align-items: center;
	justify-content: left;
	padding: var(--box-padding-sm) var(--box-padding);
	margin: 0 0 2rem 0;
	background-color: var(--orange-light);
	figure {
		padding:0;
		margin:0 1rem 0 0;
		flex: 0 0 1;
		img {
			//margin-left: -1.2rem;
		}
	}
	h1 {
		font-size: var(--font-size-xl);
	}
}