/* ------------------------------ */
/* Buttons */

button,
.btn,
input[type="submit"].action {

    padding: 0.7rem 1.2rem;
    color: var(--white);
    cursor: pointer;
    text-decoration: none;
    line-height: 1.2;
    //transition: all 0.2s ease;
    will-change: transform;
    //text-transform: uppercase;
    border: 0px solid var(--red);
    
    margin: var(--space-unit) calc(0.5 * var(--space-unit));
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    font-weight: 700;

    background-color:var(--color-primary);

    border-radius: var(--border-radius-md);
    &:hover,
    &.hover {
        color: var(--white);
        background-color:var(--red);
        text-decoration:none;

    }
    &:focus,
    &.focus {
        color: var(--white);
        background-color:var(--color-primary);
        text-decoration:none;
        outline: none;
    }
}
.btn--locale {

    border-radius: none;
    line-height: inherit;
    flex-direction: column;
    padding: 0;
    border: 0;
    color: var(--black);
    font-weight: 300;
    &:hover {
        background-color: transparent;
        color: var(--black);
    }
    span {
        border-radius: var(--border-radius-sm);
        width: 3rem;
        height: 3rem;
        width: auto;
        display: inline-block;
        overflow: hidden;
        margin-bottom: 0.8rem;
        text-align: center;
        
        img {
            object-fit: cover;
            height: 100%;
            width: auto;
            
        }

    }

}
.btn--timecard {
    background-color:var(--color-primary);
    color: white;
    border:0;
    &--time {
        font-size: var(--font-size-lg);
        margin-right: 1rem;
    }
    &--type {
        svg {
            stroke: white;
            stroke-width:1;
            margin-left: 0.2rem;
            margin-right: 0.2rem;
        }
    }

}


.btn--lg {
    box-sizing: border-box;
    white-space: inherit;
    border-width:0;
    text-align: left;
    color: var(--black);
    padding: 2rem 2rem;
    color: var(--white);
    display: inline-flex;
    width: 100%;
    margin-top: var(--box-padding-sm);
    margin-bottom: var(--box-padding-sm);
    min-height: 10rem;
    > div {
        flex: 1;
    }
    svg {
        flex: 0 0 1;

        margin-left: 1rem;
    }

    .btn__head {
        display: block;
        font-size: var(--font-size-xl);
        margin-bottom: 0.5rem;
        font-family: var(--font-headings);
    }
    &:hover {
        background-color: var(--red);
    }
    &.disabled{
        background-color: var(--grey);
    }
}


.link, a {
    cursor: pointer;
    text-decoration: underline;
    color: var(--color-primary);
}


.platform-icon {

    span {
        margin-left: auto;
        width: 52px;
        height: 52px;
        background: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        img {

            width:100%;
            height: auto;
        }

    }
}