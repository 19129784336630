

.spinner {
    z-index: 150;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, -50%);
    flex-grow: 1;
}
