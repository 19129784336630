

.summary-card {

    background-color: var(--blue);
    border-radius: var(--border-radius-sm);
    padding: var(--box-padding);
    > p {
        margin-top: 0;
    }
    label {
        font-weight: bold;
        margin-bottom: 0.2rem;
        display: inline-block;
    }

    span.helper-text {
        color: var(--blue-dark);
        font-size: small;
    }
    &--bright {
        background-color: transparent;
    }
}

.col-2 {
    
    display: flex;
}
/* .summary-card {
    margin: 0 2rem;
    background-color: #e7e2df;
    border-radius: 30px;
    padding: 2rem 3rem;

    .column_auto_2 {
        display: grid;
        grid-template-columns: auto auto;
    }
} */