
@import "styles/settings";
@import "styles/type";
@import "styles/buttons";

@import "styles/layouts/locale";
@import "styles/layouts/sections";
@import "styles/layouts/infopage";
@import "styles/components/header";
@import "styles/components/selectedheader";

@import "styles/components/nav";
@import "styles/components/footer";
@import "styles/components/forms";
@import "styles/components/locale-list";
@import "styles/components/consultant-list.scss";

@import "styles/components/summary-card.scss";
@import "styles/components/infopagelist.scss";

@import "styles/components/calender";

@import "styles/components/utilities";


@import "styles/elements/HeaderElement";
@import "styles/elements/ImageTextElement";
@import "styles/elements/ContentElement";




/* old styles */
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
    margin-top: 2rem;
}

.flex_column {
    display: flex;
    flex-direction: column;
    align-items: center;
}



/* 
.chooseButton {
    border-radius: 5px;
    margin-top: 1rem;
    text-align: left;
    padding: 1.5rem;
    width: 80vw;
    cursor: pointer;

    h2 {
        margin-top: 0;
    }

    &.disabled {
        cursor: auto;
        background-color: #d3d3d350;
    }
}
 */

.backdrop {
    position: fixed;
    z-index: 99;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #FFFFFF10;
}

.error {
    z-index: 150;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 40%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, -50%);
    background-color: #d3d3d350;
    width: 75%;
    text-align: center;
    padding: 2rem;

    .btn {
        margin-left: auto;
        margin-right: auto;
    }
}

body {
    margin: 0;
    padding: 0;
}




